<template>
  <div class="home-banner">
    <swiper ref="swiperRef" class="home-banner__slider" :options="swiperOption">
      <swiper-slide v-for="(banner, index) in 3" :key="index">
        <div class="home-banner__slide">
          <div class="container">
            <div class="home-banner__inner">
              <div class="home-banner__content">
                <h3 class="home-banner__title">
                  Клуб настоящих ценителей вечеринок!
                </h3>
                <p class="home-banner__text">
                  Акции, розыгрыши, горячие скидки на алкоголь и табачную
                  продукцию, бонусы с каждой покупки на карту Magnum Club
                </p>
                <div class="home-banner__buttons">
                  <button class="home-banner__btn">Подробнее</button>
                </div>
              </div>
              <div class="home-banner__img">
                <img src="@/assets/img/home-banner/home-banner.png" alt="" />
              </div>
            </div>

          </div>
        </div>
      </swiper-slide>
      
    </swiper>
      <div class="home-banner__arrows">
        <button class="home-banner__prev" @click="prevSlide">
          <img
            src="@/assets/img/icons/prev-arrow-gray.svg"
            alt=""
          />
        </button>
        <button class="home-banner__next" @click="nextSlide">
          <img
            src="@/assets/img/icons/next-arrow-gray.svg"
            alt=""
          />
        </button>
      </div>
    <div class="slider-pagination-wrapper">
      <div class="pagination__custom"></div>
    </div>
  </div>
</template>

<script>
import { getBanners } from "@/api";
export default {
  name: "HomeBanerSlider",
  data() {
    return {
      swiperOption: {
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        lazy: true,
        slideTo: 1,
        slidesPerView: 1,
        spaceBetween: 20,
        initialSlide: 1,
        centeredSlides: false,
        // loop: true,
        responsive: true,
        allowTouchMove: true,
        focusOnSelect: false,
        breakpoints: {
          768: {
            allowTouchMove: false,
          }
        },
        pagination: {
          el: ".pagination__custom",
          clickable: true,
          renderBullet: function (index, className) {
            return `<div class="wrapper ${className}">
              <div class="pie spinner"></div>
              <div class="pie filler"></div>
              <div class="mask"></div>
            </div>`;
          },
        },
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
      },
    };
  },
  created() {
    getBanners().then((banners) => {
      this.banners = banners;
      this.slidesTotalCount = this.banners.length;
    });
  },
  computed: {
    swiper() {
      return this.$refs?.swiperRef;
    },
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-pagination-wrapper {
  display: none;
  text-align: center;
  margin: 15px 0;
}
@media (max-width: 450px) {
  .slider-pagination-wrapper {
    display: block;
  }
}
</style>
